import React, { useEffect, useState } from "react";

import Layout from "../layout";
import SEO from "../components/seo";

import { Container, Row, Col, ModalHeader, ModalBody, Modal } from "reactstrap";
import Heading from "../components/atoms/Heading/Heading";
// import * as Strapi from "../hooks/strapi";
import Icon from "../components/atoms/Icon/Icon";
import Paragraph from "../components/atoms/Paragraph/Paragraph";
import SlickSlider from "../components/atoms/SlickSlider/SlickSlider";
import CuriosityCard from "../components/molecules/CuriosityCard/CuriosityCard";
import { useDispatch } from "react-redux";
import { hideSideDrawer, showBottomNav, showHeader } from "../state/actions";
import Button from "../components/atoms/Button/Button";
import YoutubeVideo from "../components/atoms/YoutubeVideo/YoutubeVideo";
import CuriosityImage from "../components/molecules/CuriosityImage/CuriosityImage";

const InterestingFacts = () => {
    // const curiosities = Strapi.useCuriosities();
    const [selectedCuriosity, setSelectedCuriosity] = useState(null);

    const [dragging, setDragging] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(showHeader());
        dispatch(showBottomNav());
        dispatch(hideSideDrawer());
    }, []);

    return (
        <Layout>
            <SEO title="Ciekawostki" />
            <Container>
                <Row>
                    <Col>
                        <Heading as="h1">Ciekawostki</Heading>
                    </Col>
                </Row>
                <div className="page--info">
                    <div className="btn btn--circle btn__active">
                        <Icon className="icon-monocle-min" />
                    </div>
                    <div className="page--info__content">
                        <Heading as="h2" className="h2">
                            Poznaj region!
                        </Heading>
                        <Paragraph>
                            Dowiedz się więcej o Chojnicach oraz o pobliskich
                            terenach! Odkryj pochodzenie nazw, zanurz się w
                            historii i poznaj wiele intrygujących faktów!
                        </Paragraph>
                    </div>
                </div>
                <Row>
                    <Col style={{ marginBottom: "11rem" }}>
                        {/* {curiosities.map((curiosity) => (
                            <CuriosityCard
                                key={curiosity.name}
                                curiosity={curiosity}
                                onClick={(_) => {
                                    !dragging &&
                                        setSelectedCuriosity(curiosity);
                                }}
                            />
                        ))} */}
                    </Col>
                </Row>
            </Container>
            <Modal isOpen={selectedCuriosity !== null}>
                <ModalHeader className="modal--header">
                    <div className="icon--container">
                        <div className="btn btn--circle btn__active">
                            <Icon className="icon-monocle-min" />
                        </div>
                    </div>
                    <Button
                        className="btn--circle modal--close-modal"
                        onClick={() => setSelectedCuriosity(null)}
                        aria-label="Zamknij"
                    >
                        <Icon className="icon-close-min" />
                    </Button>
                </ModalHeader>
                {selectedCuriosity && (
                    <ModalBody className="modal--body">
                        <Heading as="h2" className="text-primary">
                            {selectedCuriosity.name}
                        </Heading>
                        <Paragraph>{selectedCuriosity.description}</Paragraph>
                        <div className="slider-with-peek">
                            <SlickSlider>
                                {selectedCuriosity.images.map(
                                    (image, index) => {
                                        const hasImage =
                                            image.localFile &&
                                            image.localFile.publicURL.indexOf(
                                                "placeholder"
                                            ) === -1;
                                        return hasImage ? (
                                            <CuriosityImage
                                                image={image}
                                                key={index}
                                                alt={selectedCuriosity.name}
                                                settings={{
                                                    infinite: true,
                                                    centerMode: true,
                                                }}
                                            />
                                        ) : (
                                            ""
                                        );
                                    }
                                )}
                            </SlickSlider>
                        </div>
                        {selectedCuriosity.video.indexOf("youtube.") !== -1 && (
                            <YoutubeVideo url={selectedCuriosity.video} />
                        )}
                    </ModalBody>
                )}
            </Modal>
        </Layout>
    );
};

export default InterestingFacts;
