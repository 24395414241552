import React, { useState } from "react";
import Icon from "../Icon/Icon";

import "./youtubeVideo.scss";

export const YoutubeVideo = ({ url, ...props }) => {
    const videoID = new URL(url).searchParams.get("v");

    const [isPlaying, setPlaying] = useState(false);

    return (
        <div className="youtube-video">
            {isPlaying ? (
                <iframe
                    src={`https://www.youtube.com/embed/${videoID}?autoplay=1`}
                    width="100%"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                    {...props}
                ></iframe>
            ) : (
                <>
                    <div
                        className="youtube-video--play-button-area"
                        onClick={(_) => setPlaying(true)}
                    >
                        <div className="youtube-video--play-button">
                            <Icon className="icon-play-min" />
                        </div>
                    </div>
                    <div className="youtube-video--thumbnail">
                        <img
                            src={`https://img.youtube.com/vi/${videoID}/default.jpg`}
                            alt=""
                        />
                    </div>
                </>
            )}
        </div>
    );
};

export default YoutubeVideo;
