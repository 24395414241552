import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";

import "./curiosityImage.scss";

export const CuriosityImage = ({ image }) => {
    return (
        <div className="curiosity-image">
            <div className="curiosity-image--wrapper">
                <GatsbyImage image={getImage(image.localFile)} alt="" />
            </div>
        </div>
    );
};

export default CuriosityImage;
